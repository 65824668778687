
import SEO from '@/mixins/SEO.js'
import { getUserData } from '@/utils/tracking.js';
// import closeForm from '~/mixins/close-form';

export default {
  mixins: [SEO],
  data() {
    return {
      seo: {
        title: '',
        BirincilKampanya: 'Default-Campaign',
      },
      form: {
        fullname: '',
        company: '',
        email: '',
        phone: '',
        countryCode: '',
        privacy_check: false,
        campaign_check: false,

      },
      countryCodes: [
        { id: '90', title: '+90' },
        { id: '994', title: '+994' },
      ],
      selectedCountryCode: '90',
      isInvalid: {
        fullname: false,
        company: false,
        email: false,
        phone: false,
        privacy_check: false,
        campaign_check: false,
      },
      success: false,
    }
  },
  props: {
    // title, subtitle, campaign text and image props
    title: '',
    subtitle: '',
    privacyText: '',
    campaignText: '',
    buttonText: '',
    form_success_message: '',
    placeholderFullname: '',
    placeholderCompany: '',
    placeholderEmail: '',
    placeholderPhone: '',
  },


  methods: {

    isLetter(e) {
      const char = String.fromCharCode(e.keyCode)
      const letterRegex = /^[a-zA-Z-ğüşöçıİĞÜŞÖÇ\s]+$/
      if (letterRegex.test(char)) return true
      else e.preventDefault()
    },
    isNumber(event) {
      const charCode = (event.which) ? event.which : event.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        event.preventDefault();
      }
    },

    isEmail(e) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      return emailRegex.test(String(e));
    },

    arrayStringify(e) {
      return e.join(', ')
    },

    isPhone(e) {
      // Remove all non-numeric characters
      e = e.replace(/\D/g, '');

      // Regular expression to match valid phone numbers not starts with 0  and having exactly 10 digits.
      const phoneRegex = /^[1-9]{1}[0-9]{9}$/

      // Check if the phone number is in a valid format
      const phone = phoneRegex.test(e);

      if (!phone) {
        return false;
      }

      // Check for repeating digits of 7 or more
      const repeatingDigits = /(\d)\1{6,}/.test(e); // Match any digit repeating more than 6 times
      if (repeatingDigits) {
        return false;
      }

      // If all checks pass, the phone number is valid
      return true;
    },
    formatPhoneNumber(num) {
      // Remove all non-numeric characters and spaces
      let CC = document.getElementById('leadCountry').value;
      let formattedNum = num.replace(/\D/g, '');
      // Remove leading zeros
      formattedNum = formattedNum.replace(/^0+/, '');
      // Add '90' at the beginning
      return CC + formattedNum;
    }
    ,

    submitForm() {
      // console.log('submit')
      try {


        if (this.checkAllFields() === true) {
          this.success = true
          // console.log('validted')

          let data = {
            fullname: this.form.fullname,
            company: this.form.company,
            email: this.form.email,
            phone: this.formatPhoneNumber(this.form.phone),
            privacy_check: this.form.privacy_check,
            campaign_check: this.form.campaign_check,
          }

          if (process.client) {
            // console.log("post:", data);
            // prepareSFData and add all keys to data
            // // Same with object.assign
            const sfData = this.prepareSFData()
            // console.log("postLead:", sfData);
            data = Object.assign(data, sfData);
            // console.log("postLead:", data);
          }

        try {
              // Data Layer for Google Tag Manager
              let dataLayerObject = {
                event: "generate_lead",
                email: data.email,
                phone_number: data.phone                
             };
            window.dataLayer.push(dataLayerObject);
            // console.log("dataLayerObject:", dataLayerObject);
            this.$axios.post('/lead-forms', data)
              .then((response) => {
                console.log("API Success", response.data);
                document.location.href = '/tesekkurler/';
              })
              .catch((error) => {
                console.error("API Error", error);
              });

          } catch (error) {

            console.error("API Error:", error);
          }

        } else {
          const invalidField = document.querySelector('input:invalid')
          const invalidFields = document.querySelectorAll('input:invalid')
          // detect first invalid field and focus
          // console.log('invalidField:', invalidField)
          // console.log('invalidFields:', invalidFields)
          invalidFields.forEach((field) => {
            // if type checkbox
            field.classList.add('is-invalid')

            if (field.type === 'checkbox') {
              field.parentElement.parentElement.classList.add('is-invalid')
            }
          })

          if (invalidField) {
            invalidField.focus()
          }

        }
      } catch (err) {
        console.log(err)
      }
    },

    getBirincilKampanya() {
      // read from meta tag of current page
      // <meta data-n-head="ssr" data-hid="mikro:BirincilKampanya" name="BirincilKampanya" property="mikro:BirincilKampanya" content="birincilE-FaturaDeger">
      let birincilKampanya = document.querySelector('meta[property="mg:BirincilKampanya"]')
      if (birincilKampanya) {
        return birincilKampanya.content
      } else {
        return '7011v0000017bbDAAQ'
      }

    },

    prepareSFData() {
      // console.log("prepareSFData");
      // Get User data from cookie
      let user_data = getUserData(this.$cookies);
      let pagePath = this.$route.path;
      // console.log("gclid_data: ",gclid_data);
      // Sales Force icin gerekli ek alanlar
      // gclid-heder.js çalıştı ve >>gclid_data yok ise<< urlParams kullanılıyor.
      // Geldiği forms ayfasında URL parametreleri yok ise boş string gönderiliyor.
      let sfData = {
        utmSource: user_data['utm_source'],
        utmMedium: user_data['utm_medium'],
        utmCampaign: user_data['utm_campaign'],
        utmContent: user_data['utm_content'],
        utmTerm: user_data['utm_term'],
        gclid: (user_data['gclid']) ? user_data['gclid'] + '' : '',
        fbid: (user_data['fbid']) ? user_data['fbid'] + '' : '',
        pageURL: document.location.href,
        pagePath: pagePath,
        sourceFirst: user_data['source_first'],
        sourceLast: user_data['source_last'],
        productPageTitle: document.title,
        BirincilKampanya: this.getBirincilKampanya(),

      }
      // console.log("sfData: ", sfData);
      return sfData;

    },

    // close modal from parent component 
    // Header.vue > Button > LazyModal > closedModal() function to be called here
    closeModalForm() {
      this.success = false
      this.$emit('close-modal');
      // celar form fields
      this.form = {
        fullname: '',
        company: '',
        email: '',
        phone: '',
        privacy_check: false,
        campaign_check: false,
      }

    },
    checkAllFields() {
      this.isInvalid.fullname = (this.form.fullname.length >= 4)
      this.isInvalid.company = (this.form.company.length >= 4)
      this.isInvalid.phone = this.isPhone(this.form.phone)
      this.isInvalid.email = this.isEmail(this.form.email)
      this.isInvalid.privacy_check = this.form.privacy_check

      // console.log("this.isInvalid:", this.isInvalid) 

      this.isInvalid.fullname === false
        ? document.getElementById('fullname').classList.add('is-invalid')
        : document.getElementById('fullname').classList.remove('is-invalid');

      this.isInvalid.company === false
        ? document.getElementById('company').classList.add('is-invalid')
        : document.getElementById('company').classList.remove('is-invalid');


      this.isInvalid.email === false
        ? document.getElementById('email').classList.add('is-invalid')
        : document.getElementById('email').classList.remove('is-invalid');


      this.isInvalid.privacy_check === false
        ? document.querySelector('input[name="privacy_check"]').parentElement.parentElement.classList.add('is-invalid')
        : document.querySelector('input[name="privacy_check"]').parentElement.parentElement.classList.remove('is-invalid');

      if (this.isInvalid.phone === false) {
        document.getElementById('phone').classList.add('is-invalid')

        if (document.getElementById('phone-error') !== null) {
          document.getElementById('phone-error').remove();
        }
        let elm = document.getElementById('phone').parentNode.parentNode.appendChild(document.createElement('div'));
        elm.id = 'phone-error';
        elm.classList.add('is-danger')
        elm.innerHTML = 'Telefon numaranızı kontrol ediniz.';

      } else {
        document.getElementById('phone').classList.remove('is-invalid');
        if (document.getElementById('phone-error') !== null) {
          document.getElementById('phone-error').remove();
        }

      }

      return (
        this.form.fullname.length >= 4 &&
        this.form.company.length >= 4 &&
        this.isPhone(this.form.phone) === true &&
        this.isEmail(this.form.email) === true &&
        this.form.privacy_check === true

      )
    },

  }, // .methos
}

// (this.isInvalid.name = this.form.name.length >= 4) &&
//           (this.isInvalid.surname = this.form.surname.length >= 4) &&
//           (this.isInvalid.phone = this.isPhone(this.form.phone)) &&
//           (this.isInvalid.email = this.isEmail(this.form.email)) &&
//           (this.isInvalid.privacy_check = this.form.privacy_check) &&
//           (this.isInvalid.campaign_check = this.form.campaign_check)

