
export default {
  data() {
    return {
      showModal: false,
      smallHeight: false,
      button: {
        label: 'Tanıtım Videosunu İzle',
        url: 'https://www.youtube.com/embed/SMKPKGW083c',
        external: false,
        color: 'outline_green',
        icon: 'play_filled',
        enabled: true,
      },
    }
  },

  props: {
    hideButton: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    this.innerHeight()
    window.addEventListener('resize', this.innerHeight, {
      passive: true,
    })
  },
  beforeDestroy() {
    if (typeof window !== 'undefined') {
      window.removeEventListener('resize', this.innerHeight, {
        passive: true,
      })
    }
  },
  methods: {
    closedModal() {
      this.showModal = false;
      this.$emit("closedModal")
    },
    innerHeight() {
      this.smallHeight = window.innerHeight < 725
    }
  }
}
