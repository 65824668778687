
import TopMenu from '@/topRightMenu.json'

export default {
  name: 'TopMenu',
  data() {
    return {
      menu: [],
      menuIsOpen: false
    }
  },
  mounted() {
    this.menu = TopMenu,
      document.addEventListener('click', this.menuIsOpenFunc);
  },
  beforeDestroy() {
    document.removeEventListener('click', this.menuIsOpenFunc);
  },
  methods: {
    menuIsOpenFunc() {
      this.menuIsOpen = document.getElementById('megamenu').classList.contains('show')
    }
  }
}
