

import { EventBus } from '@/utils/event-bus.js';


export default {
  data() {
    return {
      navigationStatus: false,
      menuIsOpen: false,
    }
  },
  mounted() {
    this.navigationStatus = false
    const body = document.querySelector('body')
    body.classList.remove('no-scroll')

    // Header Animations
    const header = document.querySelector('.header')

    window.addEventListener('scroll', () => {
      if (window.pageYOffset > 70) {
        header.classList.add('header-fixed')
      } else {
        header.classList.remove('header-fixed')
      }
    })

    document.addEventListener('click', this.menuIsOpenFunc)
  },
  beforeDestroy() {
    document.removeEventListener('click', this.menuIsOpenFunc)
  },
  methods: {
    menuIsOpenFunc() {
      this.menuIsOpen = document
        .getElementById('megamenu')
        .classList.contains('show')
    },
    toggleHeader($event) {
      if (!$event.target.parentElement.classList.contains('collapsed')) {
        this.$refs.header.classList.remove('header-fixed-toggle')
      }
      this.$refs.header.classList.toggle('header-fixed-toggle')

      this.navigationStatus = !this.navigationStatus
      const body = document.querySelector('body')
      this.navigationStatus
        ? body.classList.add('no-scroll')
        : body.classList.remove('no-scroll')
    },
    // closeModalForm() {
    //   EventBus.$emit('close-modal');
    //   // this.$refs.modalRefHeader.closedModal();
    //   // this.$refs.modalRef2.closedModal();
    // },
    // triggerModal() {  
    //   EventBus.$emit('open-modal');
    // },
  },
}
