import { render, staticRenderFns } from "./LeadFormSingle.vue?vue&type=template&id=5a974302"
import script from "./LeadFormSingle.vue?vue&type=script&lang=js"
export * from "./LeadFormSingle.vue?vue&type=script&lang=js"
import style0 from "./LeadFormSingle.vue?vue&type=style&index=0&id=5a974302&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Input: require('/srv/app/components/input/Input.vue').default,Button: require('/srv/app/components/button/Button.vue').default})
