
export default {
  props: {
    socialLinks: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      social: {
        facebook: '#',
        twitter: '#',
        linkedin: '#',
        youtube: '#',
      },
    }
  },
}
