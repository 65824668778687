import { render, staticRenderFns } from "./Header.vue?vue&type=template&id=82bdf0d2"
import script from "./Header.vue?vue&type=script&lang=js"
export * from "./Header.vue?vue&type=script&lang=js"
import style0 from "./Header.vue?vue&type=style&index=0&id=82bdf0d2&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/srv/app/components/button/Button.vue').default,Button: require('/srv/app/components/button/Button.vue').default,NavigationMobile: require('/srv/app/components/navigation/NavigationMobile.vue').default,Navigation: require('/srv/app/components/navigation/Navigation.vue').default,Search: require('/srv/app/components/search/Search.vue').default,Header: require('/srv/app/components/header/Header.vue').default})
