import { render, staticRenderFns } from "./TabVerticalScroll.vue?vue&type=template&id=def014fe"
import script from "./TabVerticalScroll.vue?vue&type=script&lang=js"
export * from "./TabVerticalScroll.vue?vue&type=script&lang=js"
import style0 from "./TabVerticalScroll.vue?vue&type=style&index=0&id=def014fe&prod&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Button: require('/srv/app/components/button/Button.vue').default,ButtonLink: require('/srv/app/components/button/Link.vue').default,NavigationMenuItem: require('/srv/app/components/navigation/MenuItem.vue').default,Icon: require('/srv/app/components/icon/Icon.vue').default})
