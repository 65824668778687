import { render, staticRenderFns } from "./Navigation.vue?vue&type=template&id=86edf554"
import script from "./Navigation.vue?vue&type=script&lang=js"
export * from "./Navigation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@nuxt/webpack/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavigationMenuItem: require('/srv/app/components/navigation/MenuItem.vue').default,ButtonLink: require('/srv/app/components/button/Link.vue').default,TabVerticalScroll: require('/srv/app/components/tab/TabVerticalScroll.vue').default})
