
import TopMenu from '@/topMenu.json'

export default {
  name: 'MainMenu',
  data() {
    return {
      menum: [...TopMenu],
    }
  },
  computed: {
    menuOpenStatus() {
      return document.getElementById('megamenu').classList.contains('show')
    }
  },
  methods: {
    // click(e) {
    //   // e.target.classList.contains('show')
    //   this.$emit('menuOpenStatus', e.target.classList.contains('show'))
    // },
    footerItems(subLink) {
      return subLink ? subLink.filter((item) => item.location !== 'center') : []
    },

    subMenuClass(menuType) {
      if (menuType === 'center')
        return 'd-flex menu-item-divider pb-4 justify-content-end'
      if (menuType === 'sectionTitle')
        return 'd-flex align-items-center py-2 menu-item-divider'
      if (menuType === 'tabMenu') return ''

      return 'col-lg-3'
    },

    initUrl(url, isExternal) {
      if (!url || url.length === '') return ''

      if (isExternal) {
        return url
      }

      return url[0] === '/' ? `${url}/` : `/${url}/`
    },
  },
}
