
export default {
  name: 'TabVerticalScroll',
  props: {
    tabVerticalScroll: {
      type: Array,
      default: () => [],
    },
    subLink: {
      type: Array,
      default: () => [],
    },
  },
  computed: {

  },
  data() {
    return {
      show: this.tabVerticalScroll[0].url.substr(1),
      menuType: 'list',
    }
  },
  beforeMount() {},
  methods: {
    itemLabel(label) {
      // check if item.title contains * then remove * add new tag
      
      //return label.includes('*') ? `${label} <span class="new">yeni</span>` : label;
      if (label.includes('*')) {
        label = label.replace('*', ''); // Remove the asterisk
        return `${label} <span class="new-menu">yeni</span>`; // Add the new tag
      }
      return label;
      
    },
    activeItem(index, menuType) {
      this.show = index
      this.menuType = menuType
    },
    subLinks(item) {
      item.subLink.push({
        title: 'Tümünü Keşfet',
        url: item.url,
      })
    },
    initUrl(url, isExternal) {
      if (!url || url.length === '') return ''

      if (isExternal) {
        return url
      }

      return url[0] === '/' ? `${url}/` : `/${url}/`
    },
  },
}
